<template>
  <div>
    <!-- <DarkModeSwitcher /> -->
    <div class="" style="z-index: 2 !important"></div>
    <div class="sm:px-10">
      <div class="container block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <div class="my-auto center-lg">
            <div class="loader">
              <img src="/images/png/public/smouha-logo.svg" alt="Smouha SC" class="-intro-x w-1/2" style="width: 500px" />
            </div>
            <span class="text-6xl font-bold">
              <span>{{ i18n('app.smouha') }}</span>
              <span style="color: #bd212e">{{ i18n('app.sc') }}</span>
            </span>
            <p class="text-4xl font-bold mt-5">
              {{ i18n('app.dashboard') }}
            </p>
            <div class="runprof-logo">
              <a href="https://runprof.com/" target="_blank" rel="noopener noreferrer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/alghoneimy-175eb.appspot.com/o/public%2Frunprof.svg?alt=media&token=0a25f13c-e1ee-432a-8bf3-5cc58056b9dc"
                  class="block mx-auto" alt="" />
              </a>
            </div>
          </div>
        </div>
        <!-- END: Login Info -->

        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 xl:my-0">
          <div class="
                                                                      my-auto
                                                                      mx-auto
                                                                      bg-white
                                                                      dark:bg-dark-1
                                                                      xl:bg-transparent
                                                                      px-5
                                                                      sm:px-8
                                                                      py-8
                                                                      rounded-md
                                                                      shadow-md
                                                                      xl:shadow-none
                                                                      w-full
                                                                      sm:w-3/4
                                                                      lg:w-3/5
                                                                    ">
            <div class="xl:hidden block flex justify-center">
              <div class="loader">
                <img id="img" src="/images/png/public/smouha-logo.svg" alt="Smouha SC" class="-intro-x w-1/2" />
              </div>
            </div>
            <div class="
                                                                        intro-x
                                                                        text-gray-700
                                                                        dark:text-gray-600
                                                                        text-center
                                                                        xl:text-white
                                                                        flex
                                                                        justify-center
                                                                      ">
              <i18nFlags class="mb-10" @click="changeLoginClass()" />
            </div>
            <h2 class="
                                                                        intro-x
                                                                        font-bold
                                                                        text-2xl
                                                                        xl:text-3xl
                                                                        text-center
                                                                        xl:text-white
                                                                      ">
              {{ i18n('login.signIn') }}
            </h2>
            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
              {{ i18n('login.signInMobile') }}
            </div>
            <div class="intro-x mt-8">
              <input type="email" class="
                                                                          intro-x
                                                                          login__input
                                                                          form-control
                                                                          py-3
                                                                          px-4
                                                                          border-gray-300
                                                                          block
                                                                        " :placeholder="i18n('login.emailAddress')"
                v-model="form.email" />
              <div class="flex items-center login__input relative">
                <input :type="visible ? 'text' : 'password'" class="
                                                                            intro-x
                                                                            login__input
                                                                            form-control
                                                                            py-3
                                                                            px-4
                                                                            border-gray-300
                                                                            block
                                                                            mt-4
                                                                          " :placeholder="i18n('login.password')"
                  v-model="form.password" @keyup.enter="doSubmit" />
                <button @click="visible = !visible" class="toggle-password-btn"
                  :style="isRTL ? 'left: 0px;' : 'right: 0px;'">
                  <eye-off-icon size="1.25x" class="custom-class" v-if="!visible"></eye-off-icon>
                  <eye-icon size="1.25x" class="custom-class" v-else></eye-icon>
                </button>
              </div>
            </div>

            <div class="
                                                                        intro-x
                                                                        flex
                                                                        items-center
                                                                        justify-between
                                                                        text-gray-700
                                                                        dark:text-gray-600
                                                                        text-xs
                                                                        sm:text-sm
                                                                        mt-4
                                                                      ">
              <div class="flex items-center gap-2">
                <input id="remember-me" type="checkbox" class="form-check-input border" v-model="form.rememberMe" />
                <label for="remember-me" class="cursor-pointer select-none xl:text-white">{{ i18n('login.rememberMe')
                }}</label>
              </div>
              <router-link to="/auth/forgot-password" class="xl:text-white">
                {{ i18n('login.forgotPassword') }}
              </router-link>
            </div>

            <div v-if="error" class="
                                                                        alert alert-danger alert-dismissible
                                                                        show
                                                                        flex
                                                                        items-center
                                                                        my-2
                                                                      " role="alert" style="z-index: 2 !important">
              <AlertOctagonIcon class="w-6 h-6 mr-2 ml-2" />
              {{ i18n(errorData) }}
            </div>
            <div class="intro-x login__input grid grid-cols-12 gap-3 mt-5 xl:mt-8">
              <AppButton type="button" class="
                                                                          btn btn-primary
                                                                          py-3
                                                                          px-4
                                                                          border border-white
                                                                          align-top
                                                                          col-span-12
                                                                          flex
                                                                          items-center
                                                                        " :disabled="loading || loadingYahoo"
                :loading="loading" @click="doSubmit()">
                <strong>{{ i18n('login.login') }}</strong>
                <template v-slot:loading>
                  <app-i18n code="common.loading"></app-i18n>
                  <LoadingIcon icon="three-dots" color="#FFFFFF" style="margin: 0 4px" />
                </template>
              </AppButton>

              <!-- <router-link to="/auth/register"
                                                            class="btn btn-outline-secondary py-3 px-4 xl:mt-0 align-top xl:text-white lg:col-span-6 col-span-12"
                                                          >
                                                            {{ i18n('login.signup') }}
                                                          </router-link> -->
            </div>

            <!-- <div class="intro-x login__input mt-5 xl:mt-8">
                                                          <AppButton
                                                            type="button"
                                                            class="btn btn-primary py-3 px-4 w-full align-top flex items-center"
                                                            style="background: #720e9e"
                                                            :disabled="loading || loadingYahoo"
                                                            :loading="loadingYahoo"
                                                            @click="doSigninSocial({ provider: 'yahoo', rememberMe: form.rememberMe })"
                                                          >
                                                            <strong class="flex items-center justify-center gap-2">
                                                              <img src="/images/png/yahoo.png" style="width:30px" /> {{ i18n('login.yahoo') }}
                                                            </strong>
                                                            <template v-slot:loading>
                                                              <app-i18n code="common.loading"></app-i18n>
                                                              <LoadingIcon icon="three-dots" color="#FFFFFF" style="margin: 0 4px;" />
                                                            </template>
                                                          </AppButton>
                                                        </div> -->

            <div class="
                                                                        intro-x
                                                                        mt-10
                                                                        xl:mt-24
                                                                        text-gray-700
                                                                        dark:text-gray-600
                                                                        text-center
                                                                        xl:text-white
                                                                      "
              :class="isRTL ? 'xl:text-right' : 'xl:text-left'">
              {{ i18n('login.bySigningIn') }}<br />
              <a class="dark:text-gray-300 xl:text-white"
                :href="`https://www.smouhaclub.com/${locale}/terms-and-conditions`">{{
                  i18n('login.termsAndConditions')
                }}</a>
              {{ i18n('common.&') }}
              <a class="dark:text-gray-300 xl:text-white" :href="`https://www.smouhaclub.com/${locale}/privacy-policy`">{{
                i18n('login.privacyPolicy')
              }}</a>
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
// import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { useStore } from '@/store'
import { mapGetters, mapActions } from 'vuex'

export default defineComponent({
  // components: {
  //   DarkModeSwitcher
  // },
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.login')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.login')
    }
  },
  computed: {
    ...mapGetters({
      loading: 'auth/loading',
      loadingYahoo: 'auth/loadingYahoo'
    }),
    isRTL() {
      return this.$i18n.locale == 'ar'
    },
    locale() {
      return this.$i18n.locale
    }
  },
  mounted() {
    if (this.isRTL) {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login-rtl')
    } else {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    }
  },
  setup() {
    const store = useStore()
    const darkMode = computed(() => store.state.main.darkMode)
    const error = ref(false)
    const errorData = ref('')
    const form = ref({
      email: ref(''),
      password: ref(''),
      rememberMe: ref(false)
    })
    const visible = ref(false)
    return {
      error,
      errorData,
      form,
      darkMode,
      visible
    }
  },
  methods: {
    ...mapActions({
      doSigninSocial: 'auth/doSigninSocial',
      doSigninWithEmailAndPassword: 'auth/doSigninWithEmailAndPassword'
    }),
    validateEmail(email) {
      return email.match(
        // eslint-disable-next-line
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    },
    changeLoginClass() {
      if (this.isRTL) {
        cash('body').removeClass('login').addClass('login-rtl')
      } else {
        cash('body').removeClass('login-rtl').addClass('login')
      }
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    async doSubmit() {
      if (this.form.email.length == 0 || this.form.password.length == 0) {
        this.error = true
        this.errorData = 'login.errors.emptyFields'
      } else if (!this.validateEmail(this.form.email)) {
        this.error = true
        this.errorData = 'login.errors.wrongEmail'
      } else {
        this.error = false
        await this.doSigninWithEmailAndPassword({
          email: this.form.email,
          password: this.form.password,
          rememberMe: this.form.rememberMe
        })
      }
    }
  }
})
</script>

<style lang="scss">
.dark .btn-outline-secondary:hover {
  --tw-bg-opacity: 0.2;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}

@keyframes drawDownLine {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

.logo-web {
  margin-left: 50px;
}

[dir='rtl'] .logo-web {
  margin-right: 50px;
}

.runprof-logo {
  z-index: 999999;
  bottom: 0;
  position: absolute;
  margin-bottom: 35px;

  img {
    filter: invert(99%) sepia(0%) saturate(0%) hue-rotate(9deg) brightness(117%) contrast(100%);
  }
}

.loader {
  width: 300px;
  height: 300px;
  // line-height: 150px;

  margin-left: 100px !important;
  margin-bottom: 20px !important;
  margin-right: 100px !important;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  z-index: 0;
  text-transform: uppercase;
}

.loader:before,
.loader:after {
  opacity: 0;
  box-sizing: border-box;
  content: '\0020';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid #bd212e;
  box-shadow: 0 0 50px #bd212e, inset 0 0 50px #bd212e;
}

.loader:after {
  z-index: 1;
  animation: gogoloader 2s infinite 1s;
}

.loader:before {
  z-index: 2;
  animation: gogoloader 2s infinite;
}

@keyframes gogoloader {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.center-lg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>

<style lang="scss" scoped>
.toggle-password-btn {
  position: absolute !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  z-index: 100 !important;
  margin-top: 16px !important;
  padding: 10px !important;
}

.toggle-password-btn:focus {
  box-shadow: none !important;
}

.loader img {
  width: 500px;
}

@media (max-width:768px) {
  .loader {
    width: 150px;
    height: 150px;
    margin-left: 0px !important;
    margin-bottom: 20px !important;
    margin-right: 0px !important;
    position: relative;
    box-sizing: border-box;
    text-align: center;
    z-index: 0;
    text-transform: uppercase;
  }

  .loader #img {
    width: 150px !important;
  }
}

html body[dir=rtl] {
  padding: 0px !important;
}

@media (max-width: 639px) {

  html body[dir=rtl] {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  html body {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
</style>
